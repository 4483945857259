import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Loading } from "@shopify/polaris";
const SendMailManual = lazy(() =>
    import("./pages/Email/Dashboard/SendMailManual")
);
const ReviewManager = lazy(() => import("./pages/ReviewManager"));
const Setting = lazy(() => import("./pages/Setting"));
const EmailReviewPage = lazy(() => import("./pages/Email"));
const EmailSetting = lazy(() => import("./pages/Email/Setting/EmailSetting"));
const ListQueueEmail = lazy(() => import("./pages/Email/Dashboard/EmailTable"));
const SettingWidget = lazy(() => import("./pages/SettingWidget"));
const Home = lazy(() => import("./pages/Home"));
const DiscountVoucher = lazy(() =>
    import("./pages/DiscountVoucher/DiscountVoucher")
);
const ImportReviewPage = lazy(() => import("./pages/ReviewManager/ImportReviewPage/index"))
const OnBoarding = lazy(() => import("./pages/OnBoarding"));

function Routers() {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route
                    index={true}
                    path="/review-management"
                    element={<ReviewManager />}
                />
                <Route path="/review-management/import-review"
                    element={<ImportReviewPage/>}
                />
                <Route path="/setting" element={<Setting />} />
                <Route path="/email-review" element={<ListQueueEmail />} />
                <Route
                    path="/email-review/send-mail-manual"
                    element={<SendMailManual />}
                />
                <Route
                    path="/email-review/campaign"
                    element={<EmailReviewPage />}
                />
                <Route
                    path="/email-review/setting"
                    element={<EmailSetting />}
                />
                <Route path="/widget" element={<SettingWidget />} />
                <Route
                    path="/email-review/discount-voucher"
                    element={<DiscountVoucher />}
                />
                <Route path="/onboarding" element={<OnBoarding />} />
                <Route path="/*" element={<Home />} />
            </Routes>
        </Suspense>
    );
}

export default Routers;
